<template>
  <v-row>
    <loading :active.sync="loading"></loading>
    <v-col>
      <span
        style="
          font-family: 'IBM Plex Sans Thai';
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 40px;
        "
      >
        เพิ่มความเห็นจากศิษย์เก่า
      </span>
    </v-col>
    <v-card class="pa-6" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="2" sm="2"
            ><span class="pt-2">รายละเอียดคอมเม้น (TH)</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="4" sm="4">
            <v-textarea
              v-model="comment_desc_th"
              dense
              outlined
              auto-grow
            ></v-textarea>
          </v-col>
          <v-col cols="12" md="2" sm="2"
            ><span class="pt-2">รายละเอียดคอมเม้น (EN)</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="4" sm="4">
            <v-textarea
              v-model="comment_desc_en"
              dense
              outlined
              auto-grow
            ></v-textarea>
          </v-col>

          <v-col cols="12" md="2" sm="2"
            ><span class="pt-2">รุ่นที่</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="4" sm="4">
            <v-text-field
              hide-details
              v-model="generation_th"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="6"></v-col>

          <v-col cols="12" md="2" sm="2"
            ><span class="pt-2">ระดับการศึกษา (Level of education)</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="4" sm="4">
            <v-autocomplete
              hide-details
              v-model="course_level"
              placeholder="ระดับการศึกษา (Level of education)"
              :items="levelItems"
              item-value="id"
              dense
              outlined
              :rules="rules.required"
              @change="getMajor(course_level)"
            >
              <template v-slot:selection="{ item }">
                {{ item.name_th }}
              </template>
              <template v-slot:item="{ item }">
                {{ item.name_th }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" sm="6"></v-col>

          <v-col cols="12" md="2" sm="2"
            ><span class="pt-2">สาขาวิชาที่เรียน</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="4" sm="4">
            <v-autocomplete
              hide-details
              v-model="majorId"
              placeholder="สาขาวิชาที่เรียน (Program)"
              :items="majorItems"
              item-value="id"
              dense
              outlined
              :rules="rules.required"
              :disabled="course_level == !course_level ? true : false"
            >
              <template v-slot:selection="{ item }">
                {{ item.name_th }}
              </template>
              <template v-slot:item="{ item }">
                {{ item.name_th }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" sm="6"></v-col>

          <v-col cols="12" md="2" sm="2"
            ><span class="pt-2">คอมเม้นโดย (TH)</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="4" sm="4">
            <v-text-field
              hide-details
              v-model="comment_from_th"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2" sm="2"
            ><span class="pt-2">คอมเม้นโดย (EN)</span>
            <span style="color: red">*</span></v-col
          >

          <v-col cols="12" md="4" sm="4">
            <v-text-field
              hide-details
              v-model="comment_from_en"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2" sm="2"
            ><span class="pt-2">ตำแหน่ง (TH)</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="4" sm="4">
            <v-text-field
              hide-details
              v-model="comment_position_th"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2" sm="2"
            ><span class="pt-2">ตำแหน่ง (EN)</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="4" sm="4">
            <v-text-field
              hide-details
              v-model="comment_position_en"
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="12" sm="12" xs="12">
            <v-card outlined>
              <v-row no-gutters align="center">
                <v-col
                  cols="12"
                  md="11"
                  sm="11"
                  xs="11"
                  class="ma-4"
                  id="step-3"
                >
                  <span>เลือกรูปภาพ</span>
                  <span style="color: red">*</span>
                  <br />
                  <span style="color: red">อัพโหลดรูปภาพขนาด 500x250px</span>
                  <br />
                  <v-img :src="showImage" max-width="auto"></v-img>

                  <v-col cols="12" md="12" class="text-right">
                    <input
                      type="file"
                      accept="image/*"
                      id="file"
                      ref="q_1_1"
                      v-on:change="submitfileMain('q_1_1')"
                    />
                  </v-col>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row align="center" justify="end">
          <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
          <v-btn @click="submit('q_1_1')" dark color="#2AB3A3">บันทึก</v-btn>
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
import { VueEditor } from "vue2-editor";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
    VueEditor,
  },
  data() {
    return {
      course_level: [],
      majorId: [],
      comment_desc_en: "",
      comment_desc_th: "",
      comment_from_th: "",
      comment_from_en: "",
      comment_position_th: "",
      comment_position_en: "",
      generation_th: "",
      generation_en: "",
      img_url: "",

      pic1: "",
      picture_1: "",
      pic1URL: "",
      showImage: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
      },
      checkValidate: true,
      lazy: false,
      loading: false,

      file: [],
      levelItems: [],
      majorItems: [],
      CommentsData: [],
    };
  },
  watch: {
    productId(val) {
      if (val) {
        console.log("productId", val);
      }
    },
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.getLevel();
    this.CommentsData = JSON.parse(
      Decode.decode(localStorage.getItem("CommentsData"))
    );
    this.getOne();
  },
  methods: {
    async getOne() {
      this.loading = true;
      this.userData = JSON.parse(
        Decode.decode(localStorage.getItem("gscmAdmin"))
      );
      console.log("this.userData", this.userData);
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/studentComments/${this.CommentsData.id}`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAllbannerpe", response.data.data);
      this.course_level = response.data.data.levelId;
      this.majorId = response.data.data.majorId;
      this.comment_desc_en = response.data.data.comment_desc_en;
      this.comment_desc_th = response.data.data.comment_desc_th;
      this.comment_from_en = response.data.data.comment_from_en;
      this.comment_from_th = response.data.data.comment_from_th;
      this.comment_position_en = response.data.data.comment_position_en;
      this.comment_position_th = response.data.data.comment_position_th;
      this.generation_th = response.data.data.generation_th;
      this.generation_en = response.data.data.generation_en;
      this.img_url = response.data.data.image_url;
      this.showImage = response.data.data.image_url;
      this.loading = false;
      this.getMajor(this.course_level);
    },
    async getLevel() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/levels`
      );
      this.levelItems = response.data.data;
      console.log("levelItems", this.levelItems);
    },
    async getMajor(levelId) {
      this.majorItems = [];
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/majors?levelId=${levelId}`
      );
      this.majorItems = response.data.data;
      console.log("majorItems", this.majorItems);
    },
    cancel() {
      this.$router.push("ManageBanners");
    },
    async handleFileUpload(questionNo) {
      // this.data[questionNo] = this.$refs[questionNo].files[0];
      // console.log("file", this.data[questionNo]);
    },
    async submitfileMain(questionNo) {
      if (this.$refs[questionNo].files[0]) {
        let formData = new FormData();
        formData.append("files", this.$refs[questionNo].files[0]);
        formData.append("filename", this.$refs[questionNo].files[0].name);
        formData.append("type", "uploadfile");
        // formData.append("userId", this.userId);
        console.log("formData", formData);
        const auth = {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Bearer` + " " + this.checkuser.token,
          },
        };
        console.log("auth", auth);
        const response = await this.axios.post(
          `https://gscm-service.yuzudigital.com/files/formData`,
          formData,
          auth
        );
        if (questionNo == "q_1_1") {
          this.img_url = response.data.data.path;
          this.showImage = response.data.data.path;
        }

        console.log("SUCCESS!!");
        console.log("response", response);
      }
    },
    async submit(pic) {
      if (this.$refs.createForm.validate(true)) {
        // await this.submitfileMain(pic);
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("gscmAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          levelId: this.course_level,
          majorId: this.majorId,
          comment_desc_en: this.comment_desc_en,
          comment_desc_th: this.comment_desc_th,
          comment_from_th: this.comment_from_th,
          comment_from_en: this.comment_from_en,
          comment_position_th: this.comment_position_th,
          comment_position_en: this.comment_position_en,
          generation_th: this.generation_th,
          generation_en: this.generation_en,
          image_url: this.img_url,
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/studentComments`,
          data,
          // this.form,
          auth
        );
        console.log("createBanner", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างคบันทึกสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageBanners");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>
